import { render, staticRenderFns } from "./heritage_paper.vue?vue&type=template&id=2013fdec&scoped=true&"
import script from "./heritage_paper.vue?vue&type=script&lang=js&"
export * from "./heritage_paper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2013fdec",
  null
  
)

export default component.exports